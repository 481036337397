<template>
  <div>
    <b-row>
      <b-col
        class="mb-2"
        cols="12"
      >
        <h5 class="mb-0">
          {{ $t('message.qualification_title') }}
          <br>
          <small class="text-muted">{{ $t('message.qualification_desc') }}</small>
        </h5>
      </b-col>
    </b-row>
    <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain()"
      >
        <b-row
          v-for="(row,index) in items"
          :key="index"
          ref="row"
        >

          <b-col
            v-for="(singleInput,loopIndex) in row.singleRow.rowInputs"
            :key="loopIndex"
            :md="singleInput.md"
          >
            <GlobalSingleInput
              :key="loopIndex"
              :input="singleInput"
            />
          </b-col>

          <b-col
            lg="4"
            md="4"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>{{ $t('message.delete') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      :class="{'mt-2' : items.length > 0}"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>{{ $t('message.Add_Qualification') }}</span>
    </b-button>
  </div>
</template>

<script>

import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import GlobalSingleInput from '@/views/components/form/GlobalSingleInput'
import { convertInputsToKeyValuePair } from '@/helpers/objectHelper'
import i18n from '@/libs/i18n'

export default {
  directives: {
    Ripple,
  },
  components: {
    GlobalSingleInput,
  },
  mixins: [heightTransition],
  props: {
    qualifications: { type: Array, default: [] },
  },
  data() {
    return {
      items: [],
    }
  },
  watch: {
    qualifications(value) {
      if (value.length) {
        value.forEach(qualification => {
          this.repeateAgain(qualification)
        })
        this.items.forEach((item, index) => {
          this.items[index].singleRow.rowInputs[0].value = value[index]
        })
        this.trAddHeight(value.length * 90)
      }
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        singleRow:
            {
              rowAttributes: {},
              rowInputs: [
                {
                  md: 8,
                  label: i18n.tc('message.qualification_no', 0, {count: `${this.items.length + 1}`}),
                  type: 'text',
                  key: `qualification_${this.items.length + 1}`,
                  rules: 'required',
                  placeholder: i18n.t('placeholder.qualification'),
                  value: '',
                },
              ],
            },
      })

      this.$nextTick(() => {
        if (this.items.length === 1) this.trAddHeight(this.$refs.row[0].offsetHeight + 10)
        else this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      if (this.items.length === 0) this.initTrHeight()
      else this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight + this.items.length === 0 ? 0 : 65)
      })
    },
    getData() {
      return { qualifications: Object.values(convertInputsToKeyValuePair(this.items)) }
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
