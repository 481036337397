<template>
  <div>
    <Form :isSubmitting="isSubmitting" @submit="storeData" />
  </div>
</template>

<script>
import Form from '../shared/Form'
import useRoleAdd from './useRoleAdd'

export default {
  components: {
    Form,
  },
  setup() {
    const { storeData, isSubmitting } = useRoleAdd()

    return {
      storeData,
      isSubmitting,
    }
  },
}
</script>
