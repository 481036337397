<template>
  <div>
    <b-row
      v-for="(row,index) in inputs"
      :key="index"
    >
      <b-col
        v-for="(singleInput,loopIndex) in row.singleRow.rowInputs"
        :key="loopIndex"
        :md="singleInput.md"
      >
        <GlobalSingleInput
          :key="loopIndex"
          :input="singleInput"
        />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { reactive, watch } from '@vue/composition-api'
import GlobalSingleInput from '@/views/components/form/GlobalSingleInput'
import { convertInputsToKeyValuePair } from '@/helpers/objectHelper'
import _ from 'lodash'
import i18n from '@/libs/i18n'

export default {
  name: i18n.t('label.identification'),
  components: {
    GlobalSingleInput,
  },
  props: ['data'],
  setup(props) {
    const inputs = reactive([
      {
        singleRow:
            {
              rowAttributes: {},
              rowInputs: [
                {
                  md: 10,
                  label: i18n.t('label.Name'),
                  type: 'text',
                  key: 'name',
                  rules: 'required',
                  placeholder: i18n.t('placeholder.Technical_Assistant'),
                  value: '',
                },
                {
                  md: 2,
                  label: i18n.t('label.code'),
                  type: 'text',
                  key: 'code',
                  rules: 'required',
                  placeholder: i18n.t('placeholder.ta01'),
                  value: '',
                },
              ],
            },
      },
      {
        singleRow:
            {
              rowAttributes: {},
              rowInputs: [
                {
                  label: i18n.t('label.role_desc'),
                  type: 'textarea',
                  key: 'description',
                  rules: '',
                  placeholder: i18n.t('placeholder.role_desc'),
                  value: '',
                  description: i18n.t('message.role_desc'),
                },
              ],
            },
      },
    ])

    const getData = () => (convertInputsToKeyValuePair(inputs))

    watch(() => props.data, data => {
      if (data) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(data)) {
          inputs.forEach(row => {
            if (_.find(row.singleRow.rowInputs, { key })) {
              _.find(row.singleRow.rowInputs, { key }).value = value
            }
          })
        }
      }
    }, { deep: true, immediate: true })

    return {
      inputs,
      getData,
    }
  },
}
</script>

<style scoped>

</style>
